var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"955c9a3ab3c409af17c4a5e6e924f3fa6555a0f4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

export const sentryDefaults = {
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV || "development",
  dsn:
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    "https://a1207346992d40c9af8c21f76ffff0d7@o1254013.ingest.sentry.io/4504056522801152",
  tracesSampleRate: 1.0,
};

Sentry.init(sentryDefaults);
